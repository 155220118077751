<template>
  <div class="AccountManagement">
    <div class="item">
      <img style="width:100px;height:100px" src="@/assets/images/AccountSettings/phones.png" alt="" />
      <div class="titleNum">
        {{ hiddenMobile(userInfo.userName) }}
      </div>
      <el-button type="text" @click="dialogVisible1 = true"
        >更改手机号</el-button
      >
    </div>
    <div class="item">
      <img style="width:100px;height:100px" src="@/assets/images/AccountSettings/passWords.png" alt="" />
      <div class="titleNum">已设置密码</div>
      <el-button type="text" @click="dialogVisible2 = true">更改密码</el-button>
    </div>
    <el-dialog title="更改手机号" :visible.sync="dialogVisible1" width="600px">
      <el-form
        :model="phoneForm"
        status-icon
        :rules="rules"
        ref="phoneForm"
        label-width="100px"
      >
        <el-form-item label="原手机号" prop="oldUserName">
          <span>{{ phoneForm.oldUserName }}</span>
        </el-form-item>
        <el-form-item label="新手机号" prop="userName">
          <el-input v-model.number="phoneForm.userName"> </el-input>
        </el-form-item>

        <el-form-item label="短信验证码" prop="smsCode">
          <div class="flexBox">
            <el-input
              @keyup.enter="submitPhone('phoneForm')"
              v-model="phoneForm.smsCode"
              style="width: 320px; margin-right: 40px"
            >
            </el-input>
            <el-button
              type="text"
              :disabled="countdown > 0"
              @click.prevent="getYzm"
            >
              {{ countdown > 0 ? `重新获取(${countdown}s)` : "点击获取验证码" }}
            </el-button>
          </div>
        </el-form-item>

        <el-form-item>
          <el-form-item style="display: flex; justify-content: end">
            <el-button
              style="width: 100px"
              type="primary"
              @click="submitPhone('phoneForm')"
              >确认更改</el-button
            >
            <el-button style="width: 100px" @click="resetForm('phoneForm')"
              >取消</el-button
            >
          </el-form-item>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="更改密码" :visible.sync="dialogVisible2" width="600px">
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="原密码" prop="oldPassword">
          <el-input
            type="password"
            v-model="ruleForm.oldPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="newPassword">
          <el-input
            type="password"
            v-model="ruleForm.newPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input
            type="password"
            v-model="ruleForm.checkPass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item style="display: flex; justify-content: end">
          <el-button
            style="width: 100px"
            type="primary"
            @click="submitPass('ruleForm')"
            >确认更改</el-button
          >
          <el-button style="width: 100px" @click="resetForm('ruleForm')"
            >取消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
  
  <script>
import { getSmsCode, userUpDate, updatePwd } from "@/api";
import { encrypt, decrypt } from "@/utils/jsencrypt.js";
import { mapState } from "vuex";
export default {
  name: "AccountManagement",
  data() {
    let checkPhone = (rule, value, callback) => {
      // 手机号验证
      const reg = /^1[3456789]\d{9}$/;
      if (reg.test(value)) {
        callback();
      } else {
        return callback(new Error("请输入正确的手机号"));
      }
    };
    let validatePass = (rule, value, callback) => {
      if (this.ruleForm.checkPass !== "") {
        this.$refs.ruleForm.validateField("checkPass");
      }
      callback();
    };

    let validatePass2 = (rule, value, callback) => {
      if (value !== this.ruleForm.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    return {
      dialogVisible1: false,
      dialogVisible2: false,
      ruleForm: {
        newPassword: "",
        checkPass: "",
      },
      phoneForm: {
        smsCode: "",
      },
      countdown: 0,
      rules: {
        newPassword: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        checkPass: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
        userName: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" },
        ],
        oldUserName: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
        ],
        smsCode: [
          { required: true, message: "短信验证码不能为空", trigger: "blur" },
        ],
        oldPassword: [
          { required: true, message: "原密码不能为空", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.phoneForm.oldUserName = this.hiddenMobile(this.userInfo.userName);
  },
  methods: {
    // 手机号脱敏
    hiddenMobile(mobile) {
      if (!mobile) {
        return "--";
      }
      // 定义手机号正则表达式
      let reg = /^(1[3-9][0-9])\d{4}(\d{4}$)/;
      // 将手机号中间4位用*号进行显示
      return mobile.replace(reg, "$1****$2");
    },
    submitPhone(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            userName: this.phoneForm.userName + "",
          };
          let code = this.phoneForm.smsCode;
          userUpDate(code, params).then(({ code, msg }) => {
            if (code == 200) {
              this.$store.dispatch("userLogout");
              this.dialogVisible1 = false;
              this.$message.success(msg);

              this.$store.commit("SETLOGIN", true);
            } else {
              this.$message.error(msg);
            }
          });
        }
      });
    },
    submitPass(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = JSON.parse(JSON.stringify(this.ruleForm));
          params.oldPassword = encrypt(params.oldPassword);
          params.newPassword = encrypt(params.newPassword);
          params.checkPass = "";
          updatePwd(params).then(({ code, msg }) => {
            if (code == 200) {
              this.$store.dispatch("userLogout");
              this.dialogVisible2 = false;
              this.$message.success(msg);
              this.$store.commit("SETLOGIN", true);
            } else {
              this.$message.error(msg);
            }
          });
        }
      });
    },
    getYzm() {
      this.countdown = 60;
      let param = {
        mobile: this.phoneForm.userName,
      };
      getSmsCode(param).then(({ code, msg }) => {
        if (code !== 200) {
          this.$message.error(msg);
        }
      });
      const interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(interval);
        }
      }, 1000);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible1 = false;
      this.dialogVisible2 = false;
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
};
</script>
  
  <style lang="less" scoped>
.AccountManagement {
  display: flex;
  align-items: center;
  justify-content: center;
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    margin-left: 40px;
    .titleNum {
      font-weight: 600;
      font-size: 16px;
      color: rgba(0,0,0,85%);
    }
  }
}
</style>